import
  React,
  {
    useRef,
    useState,
    useEffect,
  }
from 'react'
import axios from 'axios'

import
  Map,
  { Marker }
from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

if (mapboxgl.hasOwnProperty('workerClass')) {
  (mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
}

import {
  TagType,
  SpotType,
} from './types'

import { Modal } from './storybook/Modal/Modal'

export const Main = () => {
  const style = 'mapbox://styles/mapbox/satellite-v9'

  const [layer, set_layer] = useState<number[]>([]);

  interface ViewStateType {
    latitude?: number,
    longitude?: number,
    zoom: number,
    minZoom?: number,
    maxZoom?: number,
    maxBounds?: [
      [number, number], // southwest
      [number, number], // northeast
    ]
  }

  const [view_state, set_view_state] = useState<ViewStateType>({
    latitude: 24.8150,
    longitude: 125.3100,
    zoom: 0,
  })

  // Because I don't now how to declare "Map" type, so I temporary use any.
  const map_ref = useRef<any>(null)

  interface ResponseType {
    data: {
      spot_list: SpotType[],
      tag_list: TagType[],
    }
  }

  const [spots, set_spots] = useState<SpotType[]>([]);
  const [tags, set_tags] = useState<TagType[]>([]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_HOST}/spots/spots`,
      params: {
        range: '宮古島',
      },
    })
    .then((response: ResponseType) => {
      set_spots(response.data.spot_list)
      set_tags(response.data.tag_list)
      set_layer(response.data.tag_list.map((tag: TagType) => tag.id))
    })
    
    setTimeout(()=>{
      if(map_ref.current) {
        let zoom = 11
        if (window.innerWidth < 1280) {
          zoom = 9
        }
        map_ref.current.flyTo({
          duration: 4000,
          zoom: zoom,
          center: [ 125.3100, 24.8150 ]
        });
        setTimeout(()=>{
          let new_view_state = {...view_state}
          new_view_state['zoom'] = zoom
          new_view_state['minZoom'] = 7
          new_view_state['maxZoom'] = 19
          new_view_state['maxBounds'] = [
            [124.7800, 24.0000], // southwest
            [125.9000, 25.6000], // northeast
          ],
          set_view_state(new_view_state)
        }, 4300)
      }
    }, 600)
  }, [])

  const Markers = () => spots.map( (spot, key) => {
    if (!layer.includes(spot.tags?.[0].id)) {
      return
    }

    let color = ''
    let shadowColor = ''

    switch (spot.tags?.[0].color) {
      case 'sky':
        color = 'border-sky-300 hover:bg-sky-100/75 shadow-sky-100'
        shadowColor = 'shadow-sky-100'
        break
      case 'emerald':
        color = 'border-emerald-300 hover:bg-emerald-100/75 shadow-emerald-100'
        shadowColor = 'shadow-emerald-100'
        break
      case 'orange':
        color = 'border-orange-300 hover:bg-orange-100/75 shadow-orange-100'
        shadowColor = 'shadow-orange-100'
        break
      case 'indigo':
        color = 'border-indigo-300 hover:bg-indigo-100/75 shadow-indigo-100'
        shadowColor = 'shadow-indigo-100'
        break
      case 'purple':
        color = 'border-purple-300 hover:bg-purple-100/75 shadow-purple-100'
        shadowColor = 'shadow-purple-100'
        break
      default:
        color = 'border-gray-300 hover:bg-gray-100/75 shadow-gray-100'
        shadowColor = 'shadow-gray-100'
    }

    return (
      <Modal
        key={key}
        toggleBtn={(
          <Marker
            latitude={ spot.latitude }
            longitude={ spot.longitude }
          >
            <div className={`border-8 border-solid rounded-full w-10 h-10 shadow-[0_0_6px_6px] cursor-pointer duration-500 ${color}`}></div>
          </Marker>
        )}

        spot={ spot }
      />
    )
  })

  const LayerMenu = () => tags.map((tag, key) => {
    let accentColor = ''
    switch (tag.color) {
      case 'sky':
        accentColor = 'accent-sky-300'
        break
      case 'emerald':
        accentColor = 'accent-emerald-300'
        break
      case 'orange':
        accentColor = 'accent-orange-300'
        break
      case 'indigo':
        accentColor = 'accent-indigo-300'
        break
      case 'purple':
        accentColor = 'accent-purple-300'
        break
      default:
        accentColor = 'accent-gray-300'
    }
    return (
      <div
        key={key}
        className='flex flex-row gap-x-2 items-center'>
        <label
          className='cursor-pointer shrink-0'
          onClick={ () => { layer.includes(tag.id) ? set_layer(layer.filter(l => l !== tag.id)||[]) : set_layer(layer.concat([tag.id])) } }
        >{ tag.name }</label>
        <input
          type='checkbox'
          checked={ layer.includes(tag.id) }
          onChange={ (e) => { !e.target.checked ? set_layer(layer.filter(l => l !== tag.id)||[]) : set_layer(layer.concat([tag.id])) } }
          className={`block w-8 h-8 border border-solid border-black cursor-pointer ${accentColor}`} />
      </div>
    )
  })

  return (
    <React.Fragment>
      <main className=''>
        <Map
          ref={map_ref}
          mapboxAccessToken={ process.env.REACT_APP_MAPBOXGL_ACCESSTOKEN||'' }
          mapStyle={ style }
          style={{
            width: '100vw',
            height: '100vh',
          }}
          { ...view_state }
          onMove={e => set_view_state(e.viewState)}
        >
          <Markers />
        </Map>
        <div className='fixed bottom-2 lg:bottom-5 right-2 lg:right-5 max-w-[96%] lg:max-w-[90%] flex flex-row flex-wrap gap-y-2 lg:gap-y-5 gap-x-2 lg:gap-x-5 text-white items-center bg-black/70 py-2 px-4 rounded'>
          <LayerMenu />
        </div>
      </main>
    </React.Fragment>
  );
}